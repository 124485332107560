import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import Footer from "../components/Footer";
import Projects from "./Projects";
import About from "./About";
import { motion, AnimatePresence } from "framer-motion";
import ProfileImg from "../components/ProfileImg";

const Home = ({ setSelectedProject, darkMode }) => {
  const [about, setAbout] = useState(false);

  const variants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 },
  };

  return (
    <>
      <AnimatePresence>
        {about ? (
          <motion.div
            key="about"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={variants}
            transition={{ duration: 1 }}
          >
            <About setAbout={setAbout} darkMode={darkMode} />
          </motion.div>
        ) : (
          <motion.div
            key="home"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={variants}
            transition={{ duration: 1 }}
          >
            <div
              className={`card shadow-lg border  border-1  ${
                darkMode
                  ? "bg-black border-white text-white"
                  : "bg-white border-black text-black"
              }h-full overflow-y-auto overflow-x-hidden`}
            >
              <div className=" sm:grid sm:grid-cols-2 sm:gap-x-12 h-full">
                <div className="about">
                  <div className="relative">
                    <motion.span
                      initial={{ opacity: 1 }}
                      animate={{
                        backgroundPosition: ["0% 100%", "0% 0%", "0% 100%"],
                        backgroundSize: "100% 200%",
                        transition: {
                          duration: 4,
                          ease: "linear",
                          repeat: Infinity,
                        },
                      }}
                      className={`relative block text-3xl font-bold ${
                        darkMode
                          ? "bg-gradient-to-b from-white via-red-500 to-white"
                          : "bg-gradient-to-b from-black via-red-500 to-black"
                      } bg-no-repeat bg-clip-text text-transparent`}
                    >
                      I'M SHIVA KUMAR DHAKAL
                    </motion.span>
                  </div>
                  <div className="block my-5 sm:hidden flex justify-center items-center">
                    <ProfileImg />
                  </div>

                  <p className="mt-5 hidden sm:block">
                    Hi, I'm Shiva, a passionate developer and innovator with a
                    knack for crafting digital solutions that blend creativity
                    with functionality. With a strong foundation in the MERN
                    stack, Next.js, and TypeScript, I specialize in JavaScript
                    and React.
                    <br />
                    <br />
                    When I'm not coding, you can find me exploring new
                    destinations, or lost in a good book.
                  </p>

                  <p
                    className="mt-1 text-gray-500 cursor-pointer"
                    onClick={() => setAbout(true)}
                  >
                    See More About me{" "}
                    <motion.div
                      className="ml-2 "
                      initial={{ x: 0 }}
                      animate={{ x: [0, 10, 0] }}
                      transition={{
                        repeat: Infinity,
                        repeatType: "loop",
                        duration: 1,
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <FaArrowRight className="inline" />
                    </motion.div>
                  </p>
                </div>

                <div className="right">
                  <div className="footer absolute top-4 right-5 sm:relative">
                    <Footer />
                  </div>
                  <div className="projects mt-5 sm:mt-0">
                    <Projects setSelectedProject={setSelectedProject} />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Home;
