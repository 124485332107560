import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Header = ({ darkMode }) => {
  return (
    <div className="w-4/5 mx-auto mt-12 ">
      <div className="flex justify-between items-center my-5 px-4">
        <motion.div
          className={`text-5xl font-bold ${
            darkMode ? "text-white" : "text-black"
          } `}
          whileHover={{ scale: 1.1, color: "#065f46" }}
          transition={{ duration: 0.5 }}
        >
          <Link to="/">SD</Link>
        </motion.div>
        <ul className="menu flex space-x-4">
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
