import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-4/5 flex justify-end mx-auto  my-5">
      <ul className="social-icons flex space-x-4 cursor-pointer">
        <li>
          <Link to="https://www.linkedin.com/in/skdhakal/" target="_blank">
            <FaLinkedin className="text-xl text-blue-700" />
          </Link>
        </li>
        <li>
          <Link to="https://github.com/theskdhakal" target="_blank">
            <FaGithub className="text-xl" />
          </Link>
        </li>
        <li>
          <a href="mailto:theskdhakal@gmail.com">
            <SiGmail className="text-xl text-red-700" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
