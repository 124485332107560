import React from "react";
import { motion } from "framer-motion";

const ProfileImg = () => {
  return (
    <motion.div
      className="relative w-full sm:w-[300px] md:w-[400px] h-full sm:h-[300px] md:h-[400px] rounded-full overflow-hidden shadow-lg bg-gradient-to-r from-cyan-500 to-blue-500 p-6"
      whileHover={{
        scale: 1.1,
        boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.3)",
      }}
      transition={{ duration: 0.5 }}
    >
      <img
        className="w-full h-full object-cover rounded-full border-4 border-white hover:border-gray-700"
        src="/image/me.png"
        alt="My profile"
      />
    </motion.div>
  );
};

export default ProfileImg;
