import React from "react";
import { motion } from "framer-motion";
import ProfileImg from "../components/ProfileImg";
import { useSound } from "use-sound";

const About = ({ setAbout, darkMode }) => {
  const skillList = [
    "html",
    "css",
    "js",
    "ts",
    "react",
    "firebase",
    "redux",
    "bootstrap",
    "figma",
    "sass",
    "git",
    "github",
    "rest",
    "node",
    "postman",
    "next",
    "mongo",
  ];

  const [play] = useSound("sound/pick.mp3", { volume: 0.1 });

  return (
    <div
      className={`card shadow-lg border border-1 h-full overflow-auto sm:overflow-hidden ${
        darkMode ? "bg-black" : "bg-white"
      }`}
      onClick={() => setAbout(false)}
    >
      <div className="mx-auto p-5">
        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-5">
          <div className="about-me">
            <h1 className="mt-5 text-2xl font-bold">Hi,</h1>
            <p>
              I'm a Sydney-based full-stack developer with a passion for
              crafting exceptional digital experiences. With over 5 projects
              under my belt, I've honed my skills in the MERN stack, Next.js,
              and TypeScript, specializing in JavaScript and React.
              <br />
              Beyond coding, I'm an avid explorer, always seeking out new
              destinations and experiences. When I'm not coding, you can find me
              lost in a good book or enjoying the tranquility of nature.
              <br />
              Join me on my journey as I continue to innovate and create,
              striving to make a positive impact through technology.
            </p>
          </div>

          <div className="hidden sm:block flex justify-center items-center">
            <ProfileImg />
          </div>
        </div>

        <div className="my-skills mt-5 pt-5">
          <h1 className="text-2xl font-bold">Skills</h1>
          <div className="flex flex-wrap justify-center mt-2">
            {skillList.map((skill, index) => (
              <motion.div
                key={index}
                whileHover={{
                  y: -10,
                  transition: {
                    type: "spring",
                    stiffness: 300,
                    damping: 10,
                  },
                }}
                className="m-3"
                onHoverStart={() => play()}
              >
                <img
                  src={`/skills/${skill}.png`}
                  className="h-12"
                  alt={`${skill} icon`}
                />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
