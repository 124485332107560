import React from "react";
import { FaGithub } from "react-icons/fa";
import { TbWorldWww } from "react-icons/tb";

const ProjectCard = ({ item, clearSelectedProject, darkMode }) => {
  return (
    <div className="card-wrapper ">
      <div
        className={`p-5 card shadow-lg border  border-1 h-full overflow-y-auto ${
          darkMode ? "bg-black border-white " : "bg-white border-black"
        }`}
      >
        <div className="flex">
          <div className="project-details  p-4 flex flex-col justify-between w-full mt-5  md:w-1/2">
            <div className="space-y-4">
              <h3 className="font-bold text-xl mb-2">{item.name}</h3>
              <p className="text-lg hidden sm:block mb-4">{item.description}</p>
              <div className="project-image  block sm:hidden w-full md:w-1/2 overflow-hidden ">
                <img
                  src={`/image/${item.images}`}
                  alt={item.name}
                  className="rounded-lg mx-auto w-full h-64 md:h-full object-cover transform transition-transform duration-300 ease-in-out hover:scale-105"
                />
              </div>
              <h3 className="font-bold text-lg my-4">Tech Stack</h3>
              <div className="tech-stack flex flex-wrap gap-2 mb-4">
                {item.techStack.map((tech, index) => (
                  <div key={index} className="text-lg">
                    <p
                      className={`border ${
                        darkMode
                          ? "bg-white text-black"
                          : "bg-gray-500 text-white"
                      } p-1 rounded`}
                    >
                      {tech}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            <div className="links flex flex-col items-start gap-2  mt-5 w-full ">
              <a href={item.sourceCode} className="link">
                <FaGithub className="inline" /> GitHub <br />
                <span className="text-blue-700 hover:text-purple-900">
                  {item.sourceCode}
                </span>
              </a>
              <a href={item.url} className="link">
                <TbWorldWww className="inline" /> Url <br />
                <span className="text-blue-700 hover:text-purple-900">
                  {item.url}
                </span>
              </a>
            </div>
          </div>

          <div className="project-image w-full hidden sm:block md:w-1/2 overflow-hidden ml-4">
            <img
              src={`/image/${item.images}`}
              alt={item.name}
              className="rounded-lg w-full h-64 md:h-full object-cover transform transition-transform duration-300 ease-in-out hover:scale-105"
            />
          </div>
        </div>

        <div className=" my-4">
          <div className="w-full  ">
            <button
              onClick={clearSelectedProject}
              className="bg-gray-500 w-full hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
