import React, { useState } from "react";
import projectData from "../Projects.json";

const Projects = ({ setSelectedProject }) => {
  const handleOnClick = (item) => {
    setSelectedProject(item);
  };
  return (
    <div className="w-4/5 mx-auto">
      <h2 className="text-xl font-bold underline">Projects</h2>
      <div className="project-list">
        <ul className="italic space-y-2 list-disc cursor-pointer">
          {projectData.map((item) => (
            <li
              key={item.id}
              onClick={() => handleOnClick(item)}
              className="thisProject text-blue-700"
            >
              {item.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Projects;
