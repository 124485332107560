import "./App.css";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import ProjectCard from "./components/ProjectCard";
import Header from "./components/Header";
import { IoInvertModeOutline } from "react-icons/io5";
import { ToastContainer } from "react-toastify";
import { Analytics } from "@vercel/analytics/react";

function App() {
  const location = useLocation();
  const [selectedProject, setSelectedProject] = useState(null);
  const [darkMode, setDarkMode] = useState(true);

  const handleOnClearSelectedItem = () => {
    setSelectedProject(null);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  console.log(darkMode);

  return (
    <div
      className={`main-body relative grid placeItems-center pb-5  ${
        darkMode ? "bg-black text-white" : "bg-white text-black"
      }`}
      style={{
        backgroundImage: `linear-gradient(to right, ${
          darkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)"
        } 0.5px, transparent 0.5px),
    linear-gradient(to bottom, ${
      darkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)"
    } 0.5px, transparent 0.5px)`,
        backgroundSize: "100px 100px",
        backgroundColor: darkMode ? "black" : "white",
      }}
    >
      <button className="absolute top-5 right-5 " onClick={toggleDarkMode}>
        <IoInvertModeOutline className="text-xl" />
      </button>
      <Header darkMode={darkMode} />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.key}>
          <Route
            path="/"
            element={
              <motion.div
                initial={{ opacity: 0, y: 0 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 1000, scale: 0 }}
                transition={{ type: "tween", duration: 0.5 }}
              >
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    rotateY: selectedProject ? 180 : 0,
                  }}
                  transition={{ type: "circ", duration: 0.5 }}
                >
                  {selectedProject ? (
                    <ProjectCard
                      item={selectedProject}
                      clearSelectedProject={handleOnClearSelectedItem}
                      darkMode={darkMode}
                    />
                  ) : (
                    <Home
                      setSelectedProject={setSelectedProject}
                      darkMode={darkMode}
                    />
                  )}
                </motion.div>
              </motion.div>
            }
          />
          <Route
            path="/contact"
            element={
              <motion.div
                initial={{ opacity: 0, y: 0 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 1000, scale: 0 }}
                transition={{ type: "tween", duration: 0.5 }}
              >
                <Contact darkMode={darkMode} />
              </motion.div>
            }
          />
        </Routes>
      </AnimatePresence>
      <ToastContainer />
      <Analytics />
    </div>
  );
}

export default App;
